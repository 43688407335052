import React from "react";

export default function HomeScreen() {
  return (
    <div>
      <img
        src="https://img.freepik.com/premium-photo/website-construction-sign-white-background-3d_768719-324.jpg"
        alt=""
        style={{ width: "100%" }}
      />
    </div>
  );
}
