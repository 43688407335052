import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../pages/home/HomeScreen";
import Menu from "../pages/menu/Menu";
import NotFound from "../pages/notFound/NotFound";
import Menu2 from "../pages/menu/Menu2";

export default function Layout() {
  return (
    <div className="">
      <BrowserRouter>
        <div className="">
          <Routes>
            <Route path="/">
              <Route index element={<HomeScreen />} />
              <Route path="home" element={<HomeScreen />} />
              <Route path="menu" element={<Menu />} />
              <Route path="seafood-menu" element={<Menu2 />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}
