import React from "react";
import { MENU } from "../../assets";

export default function Menu2() {
  return (
    <div>
      <img src={MENU.TWO_TOP_SEA} alt="" className="w-full"></img>
      <img src={MENU.TWO_BTM} alt="" className="w-full"></img>
    </div>
  );
}
